// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
	if($('.single .heateor_sss_sharing_container').length){
		var facebook = '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><g id="facebook" transform="translate(-226 -1751)"><circle id="Ellipse_74" data-name="Ellipse 74" cx="30" cy="30" r="30" transform="translate(226 1751)" fill="rgba(0,176,133,0.1)"/><g id="facebook-2" data-name="facebook" transform="translate(249 1767)"><path id="Pfad_81" data-name="Pfad 81" d="M3.556,28.664V15.214H0V10.371H3.556V6.235C3.556,2.985,5.657,0,10.5,0a29.4,29.4,0,0,1,3.409.188L13.793,4.71S12.315,4.7,10.7,4.7c-1.746,0-2.025.8-2.025,2.14v3.536h5.255L13.7,15.214H8.677v13.45H3.556" fill="rgba(0,176,133,0.5)"/></g></g></svg>';
		var x = '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><g id="twitter" transform="translate(-302 -1751)"><circle id="Ellipse_72" data-name="Ellipse 72" cx="30" cy="30" r="30" transform="translate(302 1751)" fill="rgba(0,176,133,0.1)"/><path id="Pfad_86" data-name="Pfad 86" d="M3.7,1.82H7.566L24.625,24.629H20.762ZM24.483,0,15.631,9.564,8.478,0H.069L10.979,14.589,0,26.45H2.471l9.613-10.384L19.85,26.45h8.409L16.735,11.041,26.954,0Z" transform="translate(318 1768)" fill="rgba(0,176,133,0.5)"/></g></svg>';
		var linkedin = '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><g id="linkedin" transform="translate(-150 -1751)"><circle id="Ellipse_75" data-name="Ellipse 75" cx="30" cy="30" r="30" transform="translate(150 1751)" fill="rgba(0,176,133,0.1)"/><g id="linkedin-2" data-name="linkedin" transform="translate(168.386 1769.324)"><path id="Pfad_80" data-name="Pfad 80" d="M5.5,23.144V7.529H.306V23.144ZM2.9,5.4A2.706,2.706,0,1,0,2.936,0a2.705,2.705,0,1,0-.068,5.4H2.9ZM8.369,23.144h5.19v-8.72a3.557,3.557,0,0,1,.171-1.267,2.841,2.841,0,0,1,2.663-1.9c1.878,0,2.629,1.432,2.629,3.531v8.354h5.19V14.19c0-4.8-2.561-7.028-5.975-7.028a5.17,5.17,0,0,0-4.712,2.631h.035V7.528H8.369c.068,1.465,0,15.615,0,15.615Z" fill="rgba(0,176,133,0.5)"/></g></g></svg>';
		var copy = '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><g id="link" transform="translate(-378 -1751)"><circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(378 1751)" fill="rgba(0,176,133,0.1)"/><g id="link-2" data-name="link" transform="translate(391.545 1762.507)"><path id="Pfad_84" data-name="Pfad 84" d="M34.255,9.211a5.84,5.84,0,0,0-8.273,0l1.382,1.382a3.9,3.9,0,0,1,5.519,5.519L25.1,23.9a3.9,3.9,0,0,1-5.519-5.509l1.372-1.382-1.372-1.382L18.2,17.007a5.847,5.847,0,0,0,8.293,8.244l7.787-7.787A5.84,5.84,0,0,0,34.255,9.211Z" transform="translate(-5.275 0)" fill="rgba(0,176,133,0.5)"/><path id="Pfad_85" data-name="Pfad 85" d="M4.589,28.386a3.893,3.893,0,0,1,0-5.519l7.787-7.787a3.893,3.893,0,0,1,5.519,0A3.835,3.835,0,0,1,19,17.854a3.893,3.893,0,0,1-1.139,2.774L15.8,22.721,17.184,24.1l2.063-2.063a5.857,5.857,0,0,0-8.283-8.283L3.178,21.543a5.8,5.8,0,0,0,4.146,9.938,5.908,5.908,0,0,0,4.166-1.713l-1.382-1.382a3.893,3.893,0,0,1-5.519,0Z" transform="translate(0 -1.597)" fill="rgba(0,176,133,0.5)"/></g></g></svg>';
		$('.single .heateor_sss_sharing_container .heateor_sss_sharing_ul a span').remove();
		$('.single .heateor_sss_sharing_container .heateor_sss_sharing_ul a.heateor_sss_button_linkedin').append(linkedin);
		$('.single .heateor_sss_sharing_container .heateor_sss_sharing_ul a.heateor_sss_facebook').append(facebook);
		$('.single .heateor_sss_sharing_container .heateor_sss_sharing_ul a.heateor_sss_button_x').append(x);
		$('.single .heateor_sss_sharing_container .heateor_sss_sharing_ul a.heateor_sss_button_copy_link').append(copy);
	}
	if($('.post-navigation').length){
		$.fn.isInViewport = function() {
			var elementTop = $(this).offset().top;
			var elementBottom = elementTop + $(this).outerHeight();
		
			var viewportTop = $(window).scrollTop();
			var viewportBottom = viewportTop + $(window).height();
		
			return elementBottom > viewportTop && elementTop < viewportBottom;
		};
		
		$(window).on('resize scroll', function() {
			if($('.block_biorn-numbers')){
				$('.block_biorn-numbers .elmnt-row .item-number').each(function(){
					if ($(this).isInViewport()) {
						$(this).addCLass('elmnt-in-view');
					} else {
						$(this).removeCLass('elmnt-in-view');
					}
				});	
			}
		});
		
		
		
		var loaded = false;
		var counter = 1;
			$(window).on('resize scroll', function() {
				if ($('.post-navigation > a').isInViewport()) {
					$('.post-navigation > a').addClass('in-view');
					if ($('.post-navigation > a').hasClass('in-view')) {
						if(loaded) return;
						$.ajax({
							type: 'POST',
							url: '/wp-admin/admin-ajax.php',
							dataType: "html", // add data type
							data: { action : 'get_ajax_posts2' },
							success: function( response ) {
								$( '#posts-content' ).append( response ); 
							}
						});
						loaded = true;
						counter++;
					}
				} else {
					$('.post-navigation > a').removeClass('in-view');
				}
			});
	}
	
	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
	
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
	
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};
	
	$(window).on('resize scroll', function() {
		if($('.block_biorn-numbers').length){
			$('.block_biorn-numbers .elmnt-row .item-number h2').each(function(){
				if ($(this).isInViewport()) {
					$(this).parent().parent().parent().addClass('elmnt-in-view');
				} else {
					$(this).parent().parent().parent().removeClass('elmnt-in-view');
				}
			});	
		}
	});
	
	$('.post-navigation > a').on( "click", function() {
		if($(this).hasClass('nowadsasdasdasdasd-clickable')){
			$.ajax({
				type: 'POST',
				url: '/wp-admin/admin-ajax.php',
				dataType: "html", // add data type
				data: { action : 'get_ajax_posts' },
				success: function( response ) {
					$( '#posts-content' ).append( response ); 
					$(this).remove();
				}
			});	
		}
	});
	
	
	$('.service-items .filter-item').on( "click", function() {
		$('.service-items .filter-item').removeClass('active');
		var value = $(this).data('filter');
		$(this).addClass('active');
		if(value === 'undefined' || value === undefined || value === ''){
			$('#posts-content .news-grid-item').addClass('d-block').removeClass('d-none');
		}else {
			$('#posts-content .news-grid-item').each(function() {
				var datacat = $(this).data('cat');
				if (datacat != value) {
					$(this).addClass('d-none').removeClass('d-block');
				}else{
					$(this).addClass('d-block').removeClass('d-none');
				}
			});	
		}
	});
	
	if ($(window).width() < 768) {
		$('.block_biorn .container .row .col-12 .btn-discover-more').addClass('mb-4');
	    $('.block_biorn .container .row .col-12 .btn-discover-more').parent().addClass('flex-column-reverse');
	}
	
	
	if($('.block_biorn-counter')){
		var date = $('.block_biorn-counter .row').data('time');
		var countDownDate = new Date(date).getTime();
		// Update the count down every 1 second
		var x = setInterval(function() {
		
		  // Get today's date and time
		  var now = new Date().getTime();
		  // Find the distance between now and the count down date
		  var distance = countDownDate - now;
			
		  // Time calculations for days, hours, minutes and seconds
		  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			
		  // Output the result in an element with id="demo"
		  $("#days").text(days);
		  $("#hours").text(hours);
		  $("#minutes").text(minutes);
			
		  // If the count down is over, write some text 
		  if (distance < 0) {
			clearInterval(x);
			document.getElementById("demo").innerHTML = "EXPIRED";
		  }
		}, 1000);	
	}
	
	var mItemN = $('.member-slider .member-item').length;
	if ($(window).width() <= 480) {
	  re = mItemN - 1;
	}
	if ($(window).width() >= 768) {
	  re = mItemN - 1;
	}
	if ($(window).width() > 992) {
	  re = mItemN - 2;
	}
	if ($(window).width() > 1200) {
	  re = mItemN - 3;
	}
	var result = re / mItemN;
	var result = result * 100;
	$('.member-progress-bar').css('width', ''+result+'%');
	
	$('.letter-select select').on('change', function() {
		var value = $(this).find(":selected").data('letter');
		if(value === 'undefined' || value === undefined){
			$('.member-slider .member-item').addClass('d-block').removeClass('d-none');
			$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0px, 0px)");
			var mItemN = $('.member-slider .member-item').length;
			var result = re / mItemN;
			var result = result * 100;
			$('.member-progress-bar').css('width', ''+result+'%');
			$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('4');
			  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('1');
			  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
		}else {
			$('.member-slider .member-item').each(function() {
				var dataLetter = $(this).data('letter');
				if (dataLetter != value) {
					$(this).addClass('d-none').removeClass('d-block');
				}else{
					$(this).addClass('d-block').removeClass('d-none');
					$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
					  var mItemN = $('.member-slider .member-item.d-block').length;
					  if(mItemN <= 4){
						  var rs = 1;
						  var re = mItemN;
					  }else{
						var def1 = 1;
						var def2 = 4;
						var rs = def1 + mItemC;
						var re = def2 + mItemC;
					  }
					  var result = re / mItemN;
					  var result = result * 100;
					  $('.member-progress-bar').css('width', ''+result+'%');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
					  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
					  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				}
			});
			var itemsFound = $('.member-slider .member-item.d-block').length;
			if (itemsFound == 0) {
				if($('.member-slider .unfortunately').length < 1){
					$('.member-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
				}
				$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('0');
				$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('0');
				$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text('0');
				$('.member-progress-bar').css('width', '0%');
			}else{
				$('.member-slider .unfortunately').remove();
				$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
				  var mItemN = $('.member-slider .member-item.d-block').length;
				  if(mItemN <= 4){
					  var rs = 1;
					  var re = mItemN;
				  }else{
					var def1 = 1;
					var def2 = 4;
					var rs = def1 + mItemC;
					var re = def2 + mItemC;
				  }
				  var result = re / mItemN;
				  var result = result * 100;
				  $('.member-progress-bar').css('width', ''+result+'%');
				  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
				  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
				  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
			}
		}	
	});
	
	$('.category-select select').on('change', function() {
		var value = $(this).find(":selected").data('cat');
		if(value === 'undefined' || value === undefined){
			$('.member-slider .member-item').addClass('d-block').removeClass('d-none');
			$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
			var mItemN = $('.member-slider .member-item').length;
			var result = re / mItemN;
			var result = result * 100;
			$('.member-progress-bar').css('width', ''+result+'%');
			$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('4');
			  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('1');
			  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
		}else {
			$('.member-slider .member-item').each(function() {
				var datacat = $(this).data('cat');
				if (datacat != value) {
					$(this).addClass('d-none').removeClass('d-block');
				}else{
					$(this).addClass('d-block').removeClass('d-none');
					$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
					  var mItemN = $('.member-slider .member-item.d-block').length;
					  if(mItemN <= 4){
						  var rs = 1;
						  var re = mItemN;
					  }else{
						var def1 = 1;
						var def2 = 4;
						var rs = def1 + mItemC;
						var re = def2 + mItemC;
					  }
					  var result = re / mItemN;
					  var result = result * 100;
					  $('.member-progress-bar').css('width', ''+result+'%');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
					  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
					  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				}
				var itemsFound = $('.member-slider .member-item.d-block').length;
				if (itemsFound == 0) {
					if($('.member-slider .unfortunately').length < 1){
						$('.member-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
					}
					$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('0');
					$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('0');
					$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text('0');
					$('.member-progress-bar').css('width', '0%');
				}else{
					$('.member-slider .unfortunately').remove();
					$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
					  var mItemN = $('.member-slider .member-item.d-block').length;
					  if(mItemN <= 4){
						  var rs = 1;
						  var re = mItemN;
					  }else{
						var def1 = 1;
						var def2 = 4;
						var rs = def1 + mItemC;
						var re = def2 + mItemC;
					  }
					  var result = re / mItemN;
					  var result = result * 100;
					  $('.member-progress-bar').css('width', ''+result+'%');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
					  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
					  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				}
			});	
		}
	});

	$('#company-name-search').keyup(function (e) {
	  var sTerm = $(this).val();
	  var sTerm = sTerm.toUpperCase();
	  if(e.keyCode == 8){
		  $( ".block_biorn-memberrender .member-slider .member-item").addClass('d-none').removeClass('d-block');
		  $( ".block_biorn-memberrender .member-slider .member-item .card-content h5").each(function() {
			  var strng = $(this).text();
			  if(strng.length){
				var strng = strng.toUpperCase();
				  var incStr = strng.includes(sTerm);
				  if(incStr){
					$(this).parent().parent().parent().parent().addClass('d-block').removeClass('d-none');
					$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
					  var mItemN = $('.member-slider .member-item.d-block').length;
					  if(mItemN <= 4){
						  var rs = 1;
						  var re = mItemN;
					  }else{
						var def1 = 1;
						var def2 = 4;
						var rs = def1 + mItemC;
						var re = def2 + mItemC;
					  }
					  var result = re / mItemN;
					  var result = result * 100;
					  $('.member-progress-bar').css('width', ''+result+'%');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
					  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
					  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				  }else{
					$(this).parent().parent().parent().parent().addClass('d-none').removeClass('d-block');
					$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('0');
					$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('0');
					$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text('0');
					$('.member-progress-bar').css('width', '0%');
				  }  
			  }else{
				$( ".block_biorn-memberrender .member-slider .member-item").removeClass('d-none').addClass('d-block');  
			  }
		  }); 
	  }else{
		  if(sTerm.length >= 1 ){
			  $( ".block_biorn-memberrender .member-slider .member-item").addClass('d-none').removeClass('d-block');
				$( ".block_biorn-memberrender .member-slider .member-item .card-content h5").each(function() {
					var strng = $(this).text();
					var strng = strng.toUpperCase();
					var incStr = strng.includes(sTerm);
					if(incStr){
					  $(this).parent().parent().parent().parent().addClass('d-block').removeClass('d-none');
					  $( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(0.1px, 0px)");
					  var mItemN = $('.member-slider .member-item.d-block').length;
					  if(mItemN <= 4){
						  var rs = 1;
						  var re = mItemN;
					  }else{
						var def1 = 1;
						var def2 = 4;
						var rs = def1 + mItemC;
						var re = def2 + mItemC;
					  }
					  var result = re / mItemN;
					  var result = result * 100;
					  $('.member-progress-bar').css('width', ''+result+'%');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
					  $( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
					  $( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
					}else{
					  $(this).parent().parent().parent().parent().addClass('d-none').removeClass('d-block');
					  $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('0');
						$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('0');
						$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text('0');
						$('.member-progress-bar').css('width', '0%');
					}
				});  
			}	
			var itemsFound = $('.member-slider .member-item.d-block').length;
			if (itemsFound == 0) {
				if($('.member-slider .unfortunately').length < 1){
					$('.member-progress-bar').css('width', '0%');
					$('.member-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
				}
			}else{
				$('.member-slider .unfortunately').remove();
				var result = re / itemsFound;
				var result = result * 100;
				$('.member-progress-bar').css('width', ''+result+'%');
			}
	  }
	});

	var mItemW = $('.member-slider .member-item').outerWidth();
	var mItemN = $('.member-slider .member-item').length;
	$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
	if ($(window).width() <= 480) {
	  mItemCD = mItemN - 1;
	}
	if ($(window).width() >= 768) {
	  mItemCD = mItemN - 1;
	}
	if ($(window).width() > 992) {
	  mItemCD = mItemN - 2;
	}
	if ($(window).width() > 1200) {
	  mItemCD = mItemN - 3;
	}

	var mItemRW = mItemW * mItemN;
	var mItemC = 1;
	$( ".block_biorn-memberrender  .filter-row .controller-wrapper .carousel-control-next" ).on( "click", function() {
		var mItemN = $('.member-slider .member-item.d-block').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemN - 1;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemN - 1;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemN - 2;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemN - 3;
		}
		if(mItemN > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemW * mItemC;
				var def1 = 1;
				var def2 = 4;
				var rs = def1 + mItemC;
				var re = def2 + mItemC;
				var result = re / mItemN;
				var result = result * 100;
				$('.member-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(re);
				$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
				$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$( ".block_biorn-memberrender  .filter-row .controller-wrapper .carousel-control-prev" ).on( "click", function() {
		var mItemN = $('.member-slider .member-item.d-block').length;
		if(mItemC >= 1){
			var newposX = parseInt($( ".block_biorn-memberrender .member-slider .member-item.d-block" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemW;
			if (newposX < 350) {
				var newposX = 0;
				var result = '4' / mItemN;
				var result = result * 100;
				$('.member-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text('4');
				$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text('1');
				$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				var mItemCB = $( ".block_biorn-memberrender .controller-wrapper .result-end" ).text();
				var rs = mItemC - 1;
				var mItemNC = mItemN;
				var mItemNCR = mItemNC;
				var mItemNCR = mItemCB - 1;
				var result = mItemNCR / mItemN;
				var result = result * 100;
				$('.member-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-memberrender .controller-wrapper .result-end" ).text(mItemNCR);
				$( ".block_biorn-memberrender .controller-wrapper .result-start" ).text(rs);
				$( ".block_biorn-memberrender .controller-wrapper .result-number-all" ).text(mItemN);
				$( ".block_biorn-memberrender .member-slider .member-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	
	});
	
	
	
	
	
	var mItemWE = $('.event-slider .event-item').outerWidth();
	var mItemNE = $('.event-slider .event-item').length;
	
	$( ".block_biorn-eventslider .controller-wrapper .result-number-all" ).text(mItemNE);
	if ($(window).width() <= 480) {
	  mItemCD = mItemNE - 1;
	}
	if ($(window).width() >= 768) {
	  mItemCD = mItemNE - 1;
	}
	if ($(window).width() > 992) {
	  mItemCD = mItemNE - 2;
	}
	if ($(window).width() > 1200) {
	  mItemCD = mItemNE - 3;
	}
	
	var mItemRW = mItemWE * mItemNE;
	var mItemC = 1;
	$( ".block_biorn-eventslider  .filter-row .controller-wrapper .carousel-control-next" ).on( "click", function() {
		var mItemNE = $('.event-slider .event-item').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemNE - 1;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemNE - 1;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemNE - 2;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemNE - 3;
		}
		if(mItemNE > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemWE * mItemC;
				var def1 = 1;
				var def2 = 4;
				var rs = def1 + mItemC;
				var re = def2 + mItemC;
				var result = re / mItemNE;
				var result = result * 100;
				$('.event-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-eventslider .controller-wrapper .result-end" ).text(re);
				$( ".block_biorn-eventslider .controller-wrapper .result-start" ).text(rs);
				$( ".block_biorn-eventslider .controller-wrapper .result-number-all" ).text(mItemNE);
				$( ".block_biorn-eventslider .event-slider .event-item" ).css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$( ".block_biorn-eventslider  .filter-row .controller-wrapper .carousel-control-prev" ).on( "click", function() {
		var mItemNE = $('.event-slider .event-item').length;
		if(mItemC >= 1){
			var newposX = parseInt($( ".block_biorn-eventslider .event-slider .event-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWE;
			if (newposX < 350) {
				var newposX = 0;
				var result = '4' / mItemNE;
				var result = result * 100;
				$('.event-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-eventslider .controller-wrapper .result-end" ).text('4');
				$( ".block_biorn-eventslider .controller-wrapper .result-start" ).text('1');
				$( ".block_biorn-eventslider .controller-wrapper .result-number-all" ).text(mItemNE);
				$( ".block_biorn-eventslider .event-slider .event-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				var rs = mItemC - 1;
				var mItemNEC = mItemNE;
				var mItemNECR = mItemNEC;
				var mItemNECR = mItemNEC - mItemCB;
				var result = mItemNECR / mItemNE;
				var result = result * 100;
				$('.event-progress-bar').css('width', ''+result+'%');
				$( ".block_biorn-eventslider .controller-wrapper .result-end" ).text(mItemNECR);
				$( ".block_biorn-eventslider .controller-wrapper .result-start" ).text(rs);
				$( ".block_biorn-eventslider .controller-wrapper .result-number-all" ).text(mItemNE);
				$( ".block_biorn-eventslider .event-slider .event-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	
	});
	
	
	var mItemWEA = $('.event-agenda-slider .event-agenda-item').outerWidth();
	var mItemNEA = $('.event-agenda-slider .event-agenda-item').length;
	
	console.log(mItemWEA);

	if ($(window).width() <= 480) {
	  mItemCD = mItemNEA;
	}
	if ($(window).width() >= 768) {
	  mItemCD = mItemNEA;
	}
	if ($(window).width() > 992) {
	  mItemCD = mItemNEA - 1;
	}
	if ($(window).width() > 1200) {
	  mItemCD = mItemNEA - 2;
	}
	
	var mItemRW = mItemWEA * mItemNEA;
	var mItemC = 1;
	$( ".block_biorn-eventagendaslider .carousel-control-next" ).on( "click", function() {
		var mItemWEA = $('.event-agenda-slider .event-agenda-item').outerWidth();
		var mItemNEA = $('.event-agenda-slider .event-agenda-item').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemNEA;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemNEA;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemNEA - 2;
		}
		
		if(mItemNEA > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemWEA * mItemC;
				$( ".block_biorn-eventagendaslider .event-agenda-slider .event-agenda-item" ).css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$( ".block_biorn-eventagendaslider .carousel-control-prev" ).on( "click", function() {
		var mItemNEA = $('.event-agenda-slider .event-agenda-item').length;
		var mItemWEA = $('.event-agenda-slider .event-agenda-item').outerWidth();
		if(mItemC >= 1){
			var newposX = parseInt($( ".block_biorn-eventagendaslider .event-agenda-slider .event-agenda-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWEA;
			if (newposX < 350) {
				var newposX = 0;
				$( ".block_biorn-eventagendaslider .event-agenda-slider .event-agenda-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				$( ".block_biorn-eventagendaslider .event-agenda-slider .event-agenda-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	
	});
	
	
	$('.block_biorn-eventkalender .date-wrapper #today').addClass('active').attr('id', 'active'); 
	$nC = 0;
	$( ".block_biorn-eventkalender .carousel-control-next" ).on( "click", function() {
		var mItemNEK = $('.block_biorn-eventkalender .date-wrapper #active');
		mItemNEK.removeClass('active').removeAttr('id');
		mItemNEK.next().click();
		
		var target = mItemNEK.next(); //Lets assume one element has the class active, it is the one we want to center
		var containerWidth = $('.block_biorn-eventkalender .date-wrapper button').outerWidth();
		var btnWidth = $('.block_biorn-eventkalender .date-wrapper button').outerWidth();
		var containerWidthL = $('.block_biorn-eventkalender .date-wrapper button').length;
		var containerWidth = containerWidth * containerWidthL;
		var containerWidth = containerWidth / 2 ;
		var containerWidth = containerWidthL * containerWidth;
		var containerWidth = containerWidth / 100;
		var containerWidth = (containerWidth + (btnWidth * $nC)) * 1.5;
		$('.block_biorn-eventkalender .date-wrapper button').css("transform","translate(-"+containerWidth+"px, 0px)"); 
		$nC++;
		
		
		var mItemWEA = $('.block_biorn-eventkalender .eventskalender-slider .kalender-item').outerWidth();
		var mItemNEA = $('.block_biorn-eventkalender .eventskalender-slider .kalender-item').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemNEA;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemNEA;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemNEA - 3;
		}
		
		if(mItemNEA > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemWEA * mItemC;
				$( ".block_biorn-eventkalender .eventskalender-slider .kalender-item" ).css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
		
		
		
	});
	$( ".block_biorn-eventkalender .carousel-control-prev" ).on( "click", function() {
		var mItemNEA = $('.block_biorn-eventkalender .eventskalender-slider .kalender-item').length;
		var mItemWEA = $('.block_biorn-eventkalender .eventskalender-slider .kalender-item').outerWidth();
		if(mItemC >= 1){
			var newposX = parseInt($( ".block_biorn-eventkalender .eventskalender-slider .kalender-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWEA;
			if (newposX < 350) {
				var newposX = 0;
				$( ".block_biorn-eventkalender .eventskalender-slider .kalender-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				$( ".block_biorn-eventkalender .eventskalender-slider .kalender-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	 
	});
	
	$('.block_biorn-eventkalender .category-select select').on('change', function() {
		var value = $(this).find(":selected").data('cat');
		if(value === 'undefined' || value === undefined){
			$('.eventskalender-slider .kalender-item').addClass('d-block').removeClass('d-none');
		}else {
			$('.eventskalender-slider .kalender-item').each(function() {
				var datacat = $(this).data('cat');
				if (datacat != value) {
					$(this).addClass('d-none').removeClass('d-block');
				}else{
					$(this).addClass('d-block').removeClass('d-none');
				}
				var itemsFound = $('.eventskalender-slider .kalender-item.d-block').length;
				if (itemsFound == 0) {
					if($('.eventskalender-slider .unfortunately').length < 1){
						$('.eventskalender-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
					}
				}else{
					$('.eventskalender-slider .unfortunately').remove();
				}
			});	
		}
	});
	
	setTimeout(function(){
	$('body').on('click', '.block_biorn-eventkalender #nav-mounths',function() {
		$('#calendar').addClass('active');
	});
	
	
	$('body').on('click',function(e) {
		var container = $('#calendar')
		if (!container.is(e.target) && container.has(e.target).length === 0){
			container.removeClass('active');
		}
	});
	
	$('.block_biorn-eventkalender .date-wrapper button').on('click', function() {
		$('.block_biorn-eventkalender .date-wrapper button').removeClass('active').removeAttr('id');
		$('.block_biorn-eventkalender #calendar tbody .row .cell .cell-content').removeClass('active').removeClass('today').removeAttr('id');
		$(this).addClass('active').attr('id', 'active');
		//var el = document.getElementById('active');
		//el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		var value = $(this).data('date');
		$('.block_biorn-eventkalender #calendar tbody .row .cell .cell-content.'+ value + '').addClass('active').attr('id', 'active');
		$('.eventskalender-slider .kalender-item').each(function() {
			var datacat = $(this).data('date');
			if (datacat != value) {
				$(this).addClass('d-none').removeClass('d-block');
			}else{
				$(this).addClass('d-block').removeClass('d-none');
			}
			var itemsFound = $('.eventskalender-slider .kalender-item.d-block').length;
			if (itemsFound == 0) {
				if($('.eventskalender-slider .unfortunately').length < 1){
					$('.eventskalender-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
				}
			}else{
				$('.eventskalender-slider .unfortunately').remove();
			}
		});	
	});
	
	$('body').on('click', '.block_biorn-eventkalender #calendar tbody .row .cell .cell-content',function() {
		$('.block_biorn-eventkalender #calendar tbody .row .cell .cell-content').removeClass('active').removeClass('today').removeAttr('id');
		$('.block_biorn-eventkalender .date-wrapper button').removeClass('active').removeAttr('id');
		var value = $(this).attr('datetime');
		$('.block_biorn-eventkalender .date-wrapper button.'+ value + '').addClass('active').attr('id', 'active');
		$(this).addClass('active');
		//var el = document.getElementById('active');
		//el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		$('.eventskalender-slider .kalender-item').each(function() {
			var datacat = $(this).data('date');
			if (datacat != value) {
				$(this).addClass('d-none').removeClass('d-block');
			}else{
				$(this).addClass('d-block').removeClass('d-none');
			}
			var itemsFound = $('.eventskalender-slider .kalender-item.d-block').length;
			if (itemsFound == 0) {
				if($('.eventskalender-slider .unfortunately').length < 1){
					$('.eventskalender-slider').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
				}
			}else{
				$('.eventskalender-slider .unfortunately').remove();
			}
		});	
	});
	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
	
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
	
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};
	//$(window).on('resize scroll', function() {
		//if ($('#today').isInViewport()) {
			if(document.getElementById('today')){
				var el = document.getElementById('today');
				el.scrollIntoView({ behavior: "smooth", block: "center" , inline: "start" });	
			}
		//}
	//});
	
	}, 1000);
	
	var mItemWEA = $('#project-slider  .member-item').first().outerWidth();
	var mItemNEA = $('#project-slider  .member-item').length;
	$(".biorn_block--biorn_projects .controller-wrapper .result-number-all").text(mItemNEA);
	if ($(window).width() <= 480) {
	  mItemCD = mItemNEA - 1;
	}
	if ($(window).width() >= 768) {
	  mItemCD = mItemNEA - 1;
	}
	if ($(window).width() > 992) {
	  mItemCD = mItemNEA - 2;
	}
	if ($(window).width() > 1200) {
	  mItemCD = mItemNEA - 3;
	}
	var mItemRW = mItemWEA * mItemNEA;
	var mItemC = 1;
	$(".biorn_block--biorn_projects .carousel-control-next").on( "click", function() {
		var mItemWEA = $('#project-slider  .member-item').first().outerWidth();
		var mItemNEA = $('#project-slider .member-item').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemNEA - 2;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemNEA - 3;
		}

		if(mItemNEA > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemWEA * mItemC;
				var def1 = 1;
				var def2 = 4;
				var rs = def1 + mItemC;
				var re = def2 + mItemC;
				var result = re / mItemNEA;
				var result = result * 100;
				$(".biorn_block--biorn_projects .controller-wrapper .result-end" ).text(re);
				$(".biorn_block--biorn_projects .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#project-slider .member-item").css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$(".biorn_block--biorn_projects .carousel-control-prev" ).on( "click", function() {
		var mItemWEA = $('#project-slider  .member-item').first().outerWidth();
		var mItemNEA = $('#project-slider  .member-item').length;
		if(mItemC >= 1){
			var newposX = parseInt($( "#project-slider .member-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWEA;
			if (newposX < 350) {
				var newposX = 0;
				$(".biorn_block--biorn_projects .controller-wrapper .result-end" ).text('4');
				$(".biorn_block--biorn_projects .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#project-slider .member-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				var rs = mItemC - 1;
				var mItemNEC = mItemNEA;
				var mItemNECR = mItemNEC;
				var mItemNECR = mItemNEC - mItemCB;
				$(".biorn_block--biorn_projects .controller-wrapper .result-end" ).text(mItemNECR);
				$(".biorn_block--biorn_projects .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#project-slider .member-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	
	});
	
	
	var mItemWEA = $('#video-slider  .member-item').first().outerWidth();
	var mItemNEA = $('#video-slider  .member-item').length;
	$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-number-all").text(mItemNEA);
	if ($(window).width() <= 480) {
	  mItemCD = mItemNEA - 1;
	}
	if ($(window).width() >= 768) {
	  mItemCD = mItemNEA - 1;
	}
	if ($(window).width() > 992) {
	  mItemCD = mItemNEA - 2;
	}
	if ($(window).width() > 1200) {
	  mItemCD = mItemNEA - 3;
	}
	var mItemRW = mItemWEA * mItemNEA;
	var mItemC = 1;
	$(".biorn_block--biorn_kundenliste.speaker-slider  .carousel-control-next").on( "click", function() {
		var mItemWEA = $('#video-slider  .member-item').first().outerWidth();
		var mItemNEA = $('#video-slider .member-item').length;
		if ($(window).width() <= 480) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() >= 768) {
		  mItemCD = mItemNEA - 1;
		}
		if ($(window).width() > 992) {
		  mItemCD = mItemNEA - 2;
		}
		if ($(window).width() > 1200) {
		  mItemCD = mItemNEA - 3;
		}
	
		if(mItemNEA > mItemCD){
			if(mItemCD > mItemC){
				var newposX = mItemWEA * mItemC;
				var def1 = 1;
				var def2 = 4;
				var rs = def1 + mItemC;
				var re = def2 + mItemC;
				var result = re / mItemNEA;
				var result = result * 100;
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-end" ).text(re);
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#video-slider .member-item").css("transform","translate(-"+newposX+"px, 0px)");
				mItemC++;	
			}	
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$(".biorn_block--biorn_kundenliste.speaker-slider  .carousel-control-prev" ).on( "click", function() {
		var mItemWEA = $('#video-slider  .member-item').first().outerWidth();
		var mItemNEA = $('#video-slider  .member-item').length;
		if(mItemC >= 1){
			var newposX = parseInt($( "#video-slider .member-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWEA;
			if (newposX < 350) {
				var newposX = 0;
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-end" ).text('4');
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#video-slider .member-item" ).css("transform","translate("+newposX+"px, 0px)");
			}else{
				var rs = mItemC - 1;
				var mItemNEC = mItemNEA;
				var mItemNECR = mItemNEC;
				var mItemNECR = mItemNEC - mItemCB;
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-end" ).text(mItemNECR);
				$(".biorn_block--biorn_kundenliste.speaker-slider  .controller-wrapper .result-number-all" ).text(mItemNEA);
				$( "#video-slider .member-item" ).css("transform","translate(-"+newposX+"px, 0px)");
			}
			mItemC--;	
			mItemCB++;
		}	
	});
	
	
	var mItemWEA = $('.block_biorn-lighthouse-alternate #lighthouse-slider  .story-item').outerWidth();
	var mItemNEA = $('.block_biorn-lighthouse-alternate #lighthouse-slider  .story-item').length;
	$(".block_biorn-lighthouse-alternate .controller-wrapper .result-number-all").text(mItemNEA);
	mItemCD = 2;
	var mItemRW = mItemWEA * mItemNEA;
	var mItemC = 1;
	$(".block_biorn-lighthouse-alternate .carousel-control-next").on( "click", function() {
		var mItemNEA = $('.block_biorn-lighthouse-alternate #lighthouse-slider .story-item').length;
		if($(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text() == 2){
			$(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text('1');
		}else{
			$(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text('2');
		}
	});
	var mItemC = 1;
	var mItemCB = 1;
	$(".block_biorn-lighthouse-alternate .carousel-control-prev" ).on( "click", function() {
		var mItemNEA = $('.block_biorn-lighthouse-alternate #lighthouse-slider  .story-item').length;
		
			var newposX = parseInt($( ".block_biorn-lighthouse-alternate #lighthouse-slider .story-item" ).first().css('transform').split(',')[4]);
			var newposX = Math.abs(newposX);
			var newposX = newposX - mItemWEA;
			if($(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text() == 2){
				$(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text('1');
			}else{
				$(".block_biorn-lighthouse-alternate .controller-wrapper .result-end" ).text('2');
			}
			
			mItemC--;	
			mItemCB++;
	});
	
	$( ".block_biorn-eventsrender .controller-wrapper .carousel-control-prev" ).on( "click", function() {
		var mItemNE = $( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text();
		if(mItemNE > 4){
			var mItemNE = '4';
			$( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text(mItemNE);	
		}else{
			var mItemNE = '8';
			$( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text(mItemNE);	
		}
	});	
	$( ".block_biorn-eventsrender .controller-wrapper .carousel-control-next" ).on( "click", function() {
		var mItemNE = $( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text();
		if(mItemNE < 5){
			var mItemNE = '8';
			$( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text(mItemNE);	
		}else{
			var mItemNE = '4';
			$( ".block_biorn-eventsrender .controller-wrapper .result-start" ).text(mItemNE);	
		}
	});	
	
	
	$('.investment-category-select select').on('change', function() {
		var value = $(this).find(":selected").data('cat');
		if(value === 'undefined' || value === undefined){
			$('.investmentrender .investment-item').addClass('d-block').removeClass('d-none');
		}else {
			$('.investmentrender .investment-item').each(function() {
				var datacat = $(this).data('cat');
				if (datacat != value) {
					$(this).addClass('d-none').removeClass('d-block');
				}else{
					$(this).addClass('d-block').removeClass('d-none');
				}
				var itemsFound = $('.investmentrender .investment-item.d-block').length;
				if (itemsFound == 0) {
					if($('.investmentrender .unfortunately').length < 1){
						$('.investmentrender').append('<h5 class="mb-4 color-gruen unfortunately">unfortunately the search did not return any hits</h5>');	
					}
				}else{
					$('.investmentrender .unfortunately').remove();
				}
			});	
		}
	});
	
	
	let itemsI = document.querySelectorAll('.image-carousel .carousel-item')
	
	itemsI.forEach((el) => {
		if ($(window).width() <= 480) {
		  var minPerSlide = 1
		}
		if ($(window).width() >= 768) {
		  var minPerSlide = 2
		}
		if ($(window).width() > 992) {
		  var minPerSlide = 3
		}
		let next = el.nextElementSibling
		for (var i=1; i<minPerSlide; i++) {
			if (!next) {
				// wrap carousel by using first child
				next = itemsI[0]
			  }
			let cloneChild = next.cloneNode(true)
			el.appendChild(cloneChild.children[0])
			next = next.nextElementSibling
		}
	});
	
	let itemsE = document.querySelectorAll('.events-slider .carousel-item');
	$('.events-slider .carousel-item').first().addClass('active');
	itemsE.forEach((el) => {
		if ($(window).width() <= 480) {
		  var minPerSlide = 1
		}
		if ($(window).width() >= 768) {
		  var minPerSlide = 2
		}
		if ($(window).width() > 992) {
		  var minPerSlide = 3
		}
		if ($(window).width() > 1200) {
		  var minPerSlide = 4

		}
		let next = el.nextElementSibling
		for (var i=1; i<minPerSlide; i++) {
			if (!next) {
				// wrap carousel by using first child
				next = itemsE[0]
			  }
			let cloneChild = next.cloneNode(true)
			el.appendChild(cloneChild.children[0])
			next = next.nextElementSibling
		}
	});

	
	//let itemsM = document.querySelectorAll('.member-carousel .carousel-item')
	
	//itemsM.forEach((el) => {
		//const minPerSlide = 4
		//let next = el.nextElementSibling
		//for (var i=1; i<minPerSlide; i++) {
			//if (!next) {
				//// wrap carousel by using first child
				//next = itemsM[0]
//			  }
			//let cloneChild = next.cloneNode(true)
			//el.appendChild(cloneChild.children[0])
			//next = next.nextElementSibling
		//}
	//});
	
	$(document).on("click mousemove","#lighthouse-slider .story-item",function(e){ 
		$(this).each(function( index ) {
		  var parentOffset = $("#lighthouse-slider").offset(); 
		  var x = e.pageX - parentOffset.left;
		  var y = e.pageY - parentOffset.top;
		  var newposX = x - 55;
		  var newposY = y - 100; 
		  if(!$(this).hasClass('last-item')){
			$(".svg-wrapper").addClass('active').css("transform","translate3d("+newposX+"px,"+newposY+"px,0px)");  
		  }
		});
		//$(".overlay-headline").css("transform","translate3d("+newposX+"px,31px,0px)");
	}); 
	
	$( "#lighthouse-slider" ).on( "click mousemove", function() {
	  $(".svg-wrapper").removeClass('active');
	} );
	
	$( "#cs-slider .carousel-control-next" ).on( "click", function() {
		var num = $(this).prev().prev().find('.current').text();
		if(num == '01'){
			$(this).prev().prev().find('.current').text('02');
		}else if(num == '02'){
			$(this).prev().prev().find('.current').text('03');
		}else if(num == '03') {
			$(this).prev().prev().find('.current').text('01');
		}
	} );
	
	$( "#cs-slider .carousel-control-prev" ).on( "click", function() {
		var num2 = $(this).prev().find('.current').text();
		if(num2 == '01'){
			$(this).prev().find('.current').text('03');
		}else if(num2 == '02'){
			$(this).prev().find('.current').text('01');
		}else if(num2 == '03') {
			$(this).prev().find('.current').text('02');
		}
	} );
	
 setTimeout(function () {
	var maxHeight = -1;
	var elmnt = jQuery('.kundenliste_slider.slider-is-kundenliste .biorn_kundenliste');
	$(elmnt).each(function () {
	  maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	  $(this).height(maxHeight);
	});
  }, 1000);
  
  setTimeout(function () {
	  var maxHeight = -1;
	  var elmnt = jQuery('.kundenliste_slider.slider-is-kundenliste .biorn_kundenliste');
	  $(elmnt).each(function () {
		maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
		$(this).height(maxHeight);
	  });
	}, 1000);
  
  setTimeout(function () {
	  var maxHeight = -1;
	  var elmnt = jQuery('#biorn_lighthouse-alternate_lighthouse .carousel-item');
	  $(elmnt).each(function () {
		maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
		$(this).height(maxHeight);
	  });
	}, 1000);
  
  
  (function ($) {
	function process_biorn_sliders() {
			if ($('div.slider-is-corevalues')) {
				$('div.slider-is-corevalues').each(function (c, e) {
			
				  // -> corevalues (Logos & CO)
				  // Wrap elms inside wrapper!
			
				  if ($(e).hasClass('slider-is-corevalues')) {
			
					let items = document.querySelectorAll('.carousel.slider-is-corevalues .carousel-item');
			
					let wrapNum = 2;  // default as fallback
			
					if ($(window).width() <= 480) {
					  wrapNum = 3;
					}
					if ($(window).width() >= 775) {
					  wrapNum = 4;
					}
			
					// OUTDATED:
					// items.forEach((el) => {
					//   const minPerSlide = wrapNum
					//   let next = el.nextElementSibling
					//   for (var i = 1; i < minPerSlide; i++) {
					//     if (!next) {
					//       next = items[0]
					//     }
					//     let cloneChild = next.cloneNode(true)
					//     el.appendChild(cloneChild.children[0])
					//     next = next.nextElementSibling
					//   }
					// });
			
					var itemcounter = 1;
					items.forEach((el) => {
					  const minPerSlide = wrapNum;
					  let next = items[itemcounter];
					  for (var i = 1; i < minPerSlide; i++) {
						if(next){
						  let cloneChild = next.cloneNode(true);
						  el.appendChild(cloneChild.children[0]);
						  itemcounter++;
						  next = items[itemcounter];
						} else {
						  itemcounter++;
						}
					  }
					  itemcounter++;
					});
			
					$(items).css({ 'transition-duration': (5000 * wrapNum) + 'ms' }); // calculate transition duration
					$(e).find('.inner-item').css({ 'width': (100 / wrapNum) + '%' }); // set width per box inside wrapper row
			
					$('.carousel.slider-is-corevalues .carousel-item').each(function(){
					  if($(this).children().length <= 1){
						$(this).css({'border':'1px dotted red'});
						$(this).remove();
					  }
					});
			
				  }
				});
			  }
	  
  
  
	  if ($('div.slider-is-kundenliste')) {
		$('div.slider-is-kundenliste').each(function (c, e) {

		  // -> kundenliste (Logos & CO)
		  // Wrap elms inside wrapper!
  
		  if ($(this).hasClass('slider-is-kundenliste')) {
  
			let items = e.querySelectorAll('.carousel.slider-is-kundenliste .carousel-item');
  
			let wrapNum = 2;  // default as fallback
  
			if ($(window).width() <= 480) {
			  wrapNum = 2;
			}
			if ($(window).width() >= 775) {
			  wrapNum = 4;
			}
			if ($(window).width() >= 1024) {
			  wrapNum = 6;
			}
			if ($(window).width() > 1200) {
			  wrapNum = 8;
			}
  
			// OUTDATED:
			// items.forEach((el) => {
			//   const minPerSlide = wrapNum
			//   let next = el.nextElementSibling
			//   for (var i = 1; i < minPerSlide; i++) {
			//     if (!next) {
			//       next = items[0]
			//     }
			//     let cloneChild = next.cloneNode(true)
			//     el.appendChild(cloneChild.children[0])
			//     next = next.nextElementSibling
			//   }
			// });
  
			var itemcounter = 1;
			items.forEach((el) => {
			  const minPerSlide = wrapNum;
			  let next = items[itemcounter];
			  for (var i = 1; i < minPerSlide; i++) {
				if(next){
				  let cloneChild = next.cloneNode(true);
				  el.appendChild(cloneChild.children[0]);
				  itemcounter++;
				  next = items[itemcounter];
				} else {
				  itemcounter++;
				}
			  }
			  itemcounter++;
			});
  
			$(items).css({ 'transition-duration': (5000 * wrapNum) + 'ms' }); // calculate transition duration
			$(e).find('.inner-item').css({ 'width': (100 / wrapNum) + '%' }); // set width per box inside wrapper row
  
			$(this).find('.carousel-item').each(function(){
			  if($(this).children().length <= 1){
				$(this).css({'border':'1px dotted red'});
				$(this).remove();
			  }
			});
  
		  }
		});
	  }
	  
	  if ($('div.slider-is-kundenliste2')[0]) {
		$('div.slider-is-kundenliste2').each(function (c, e) {
		if ($(e).hasClass('slider-is-kundenliste2')) {
		
			let items2 = document.querySelectorAll('.carousel.slider-is-kundenliste2 .biorn-kundenliste');
		
			let wrapNum = 2;  // default as fallback
		
			if ($(window).width() <= 480) {
			  wrapNum = 3;
			}
			if ($(window).width() >= 775) {
			  wrapNum = 4;
			}
			if ($(window).width() > 1024) {
			  wrapNum = 6;
			}
		
			// OUTDATED:
			// items2.forEach((el) => {
			//   const minPerSlide = wrapNum
			//   let next = el.nextElementSibling
			//   for (var i = 1; i < minPerSlide; i++) {
			//     if (!next) {
			//       next = items2[0]
			//     }
			//     let cloneChild = next.cloneNode(true)
			//     el.appendChild(cloneChild.children[0])
			//     next = next.nextElementSibling
			//   }
			// });
		
			var itemcounter2 = 1;
			items2.forEach((el) => {
			  const minPerSlide = wrapNum;
			  let next = items2[itemcounter2];
			  for (var i = 1; i < minPerSlide; i++) {
				if(next){
				  let cloneChild = next.cloneNode(true);
				  el.appendChild(cloneChild.children[0]);
				  itemcounter2++;
				  next = items2[itemcounter2];
				} else {
				  itemcounter2++;
				}
			  }
			  itemcounter2++;
			});
		
			$(items2).css({ 'transition-duration': (5000 * wrapNum) + 'ms' }); // calculate transition duration
			$(e).find('.inner-item').css({ 'width': (100 / wrapNum) + '%' }); // set width per box inside wrapper row

		
		  }
		  });
	  }
	  
	  if ($('div.slider-is-startups')[0]) {
		  $('div.slider-is-startups').each(function (c, e) {
	  
			
			  
			  if ($(e).hasClass('slider-is-startups')) {
			  
				let items3 = document.querySelectorAll('.carousel.slider-is-startups .carousel-item');
			  
				let wrapNum = 2;  // default as fallback
			  
				if ($(window).width() <= 480) {
				  wrapNum = 3;
				}
				if ($(window).width() >= 775) {
				  wrapNum = 4;
				}
			  
				// OUTDATED:
				// items3.forEach((el) => {
				//   const minPerSlide = wrapNum
				//   let next = el.nextElementSibling
				//   for (var i = 1; i < minPerSlide; i++) {
				//     if (!next) {
				//       next = items3[0]
				//     }
				//     let cloneChild = next.cloneNode(true)
				//     el.appendChild(cloneChild.children[0])
				//     next = next.nextElementSibling
				//   }
				// });
			  
				var itemcounter3 = 1;
				items3.forEach((el) => {
				  const minPerSlide = wrapNum;
				  let next = items3[itemcounter3];
				  for (var i = 1; i < minPerSlide; i++) {
					if(next){
					  let cloneChild = next.cloneNode(true);
					  el.appendChild(cloneChild.children[0]);
					  itemcounter3++;
					  next = items3[itemcounter3];
					} else {
					  itemcounter3++;
					}
				  }
				  itemcounter3++;
				});
			  
				$(items3).css({ 'transition-duration': (10000 * wrapNum) + 'ms' }); // calculate transition duration
				$(e).find('.inner-item').css({ 'width': 'calc( ' + (100 / wrapNum) + '% - 50px)' }); // set width per box inside wrapper row
			  
				$('.carousel.slider-is-startups .carousel-item').each(function(){
				  if($(this).children().length <= 1){
					$(this).css({'border':'1px dotted red'});
					$(this).remove();
				  }
				});
			  
			  }
		  });
		}
	}
  
	process_biorn_sliders();
  
  })(jQuery);
  
$(function() {
	if ($(window).width() > 1024) {
	  
	  if($('.block_biorn-lighthouse').length){
	  
	  var width_2 = $('.block_biorn-lighthouse ').width();
	  
	  var items = $(".block_biorn-lighthouse .lighthouse-slider .story-item").length;
	  var items = items - 2;
	  var percentage = 50 * items;
	  var percentage = '-'+percentage+'%';
  
	  var controller_1 = new ScrollMagic.Controller();
  
	  var horizontal_2 = new TimelineMax({onUpdate:showRedProgress})
		 
		  .fromTo(".block_biorn-lighthouse .lighthouse-slider", 1, { 
			  x: "0%",
		  }, {
			  x: percentage,
			  ease: Linear.easeNone
		  });
  
	  new ScrollMagic.Scene({
			  triggerElement: ".block_biorn-lighthouse",
			  triggerHook: "onLeave",
			  duration: width_2,
		  })
		  //.addIndicators({name: 'horizontal - 2'})
		  .setPin(".block_biorn-lighthouse")
		  .setTween(horizontal_2)
		  .addTo(controller_1);
		  
		  var redProgress = $(".progress-bar");
		  function showRedProgress() {
			TweenLite.set(redProgress, {scaleX:horizontal_2.progress(), transformOrigin:"0px 0px"});
		  }
	  }
	  }	
  });
  
  $(function() {
	  if ($(window).width() > 1024) {
		if($('.block_biorn-process').not('.bigger-witdh').not('.no-scroll').length){
		
		var width_2 = $('.block_biorn-process ').width();
	
	
	
		var controller_1 = new ScrollMagic.Controller();
	
		var horizontal_2 = new TimelineMax({onUpdate:showRedProgress})
		   
			.fromTo(".block_biorn-process .process-slider", 1, { 
				x: "0%",
			}, {
				x: "-150%",
				ease: Linear.easeNone
			});
	
		new ScrollMagic.Scene({
				triggerElement: ".block_biorn-process",
				triggerHook: "onLeave",
				duration: width_2,
			})
			//.addIndicators({name: 'horizontal - 2'})
			.setPin(".block_biorn-process")
			.setTween(horizontal_2)
			.addTo(controller_1);
			
			var redProgress = $(".progress-bar");
			function showRedProgress() {
			  TweenLite.set(redProgress, {scaleX:horizontal_2.progress(), transformOrigin:"0px 0px"});
			}
		}	
		}	
	});
	
	$(function() {
		if ($(window).width() > 1024) {
		if($('.block_biorn-process.bigger-witdh').not('.no-scroll').length){
		
		var width_2 = $('.block_biorn-process.bigger-witdh ').width();
	
	
	
		var controller_1 = new ScrollMagic.Controller();
	
		var horizontal_2 = new TimelineMax({onUpdate:showRedProgress})
		   
			.fromTo(".block_biorn-process.bigger-witdh .process-slider", 1, { 
				x: "0%",
			}, {
				x: "-200%",
				ease: Linear.easeNone
			});
	
		new ScrollMagic.Scene({
				triggerElement: ".block_biorn-process.bigger-witdh",
				triggerHook: "onLeave",
				duration: width_2,
			})
			//.addIndicators({name: 'horizontal - 2'})
			.setPin(".block_biorn-process.bigger-witdh")
			.setTween(horizontal_2)
			.addTo(controller_1);
			
			var redProgress = $(".progress-bar");
			function showRedProgress() {
			  TweenLite.set(redProgress, {scaleX:horizontal_2.progress(), transformOrigin:"0px 0px"});
			}
		}	
	}
	});
  
}); 